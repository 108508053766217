import { apiAxios } from '@shared/http/api'
import useInterceptorsHandler from '@shared/http/interceptorsHandlers'

export default ((authStore) => {
  const options = {
    authStore,
  }

  const {
    handleResponseSuccess,
    handleResponseFailure,
    handleRequestSuccess,
    handleRequestFailure,
  } = useInterceptorsHandler()

  apiAxios.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    (response) => (handleResponseSuccess(response)),
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    (error) => (handleResponseFailure(error, options)),
  )

  apiAxios.interceptors.request.use(
    async (config) => (handleRequestSuccess(config, options)),
    (error) => (handleRequestFailure(error, options)),
  )
})
